// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--I-njW";
export var animateScrollOut = "styles-module--animateScrollOut--LdOR6";
export var blueCircle = "styles-module--blueCircle--MEdko";
export var colorSequence = "styles-module--colorSequence--0UEkE";
export var container = "styles-module--container--kNu9w";
export var content = "styles-module--content--84qpU";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--plT1n";
export var dotOrgBang = "styles-module--dotOrgBang--whCzc";
export var grow = "styles-module--grow--AltDW";
export var growAndShrink = "styles-module--growAndShrink--xpoMb";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--HfX25";
export var left = "styles-module--left--U65b9";
export var leftCol = "styles-module--leftCol--PQxxQ";
export var links = "styles-module--links--CWyU7";
export var moveBg = "styles-module--move-bg--o+GTR";
export var repeater = "styles-module--repeater--fT8vp";
export var right = "styles-module--right--kPGiN";
export var rightCol = "styles-module--rightCol--ohNBr";
export var rotateLoop = "styles-module--rotateLoop--HC9p2";
export var section = "styles-module--section--l3v7D";
export var sectionWrap = "styles-module--sectionWrap--QpWzR";
export var spin = "styles-module--spin--vWXyq";
export var spinCounter = "styles-module--spinCounter--+ou2X";
export var stickerFloat = "styles-module--stickerFloat--qACYu";
export var subtitle = "styles-module--subtitle---pQnQ";
export var title = "styles-module--title--m2IAp";