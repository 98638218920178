import React from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const TwoColWithMedia = ({ data }) => {
  const {
    primary: {
      title,
      content,
      image_on_right: imgOnRight,
    }
  } = data;

  const titleHtml = title?.html.replace('{b}', '<strong class="heavy">')
                               .replace('{/b}', '</strong>');
  const contentHtml = content?.html;

  return (
    <Section jumpId={'employeeinvolvement'} sliceName={'TwoColWithMedia'} className={styles.section}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.title} dangerouslySetInnerHTML={{__html: titleHtml}} />
          <div className={styles.content} dangerouslySetInnerHTML={{__html: contentHtml}} />
        </div>
        <div>
          <Image image={imgOnRight} />
        </div>
      </div>
    </Section>
  )
}

export default TwoColWithMedia;