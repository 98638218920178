import React from 'react';
import { Section, Link } from 'components';
import dotOrgBang from 'images/yellow-dotorg-explosion.svg';
import * as styles from './styles.module.scss';

const TwoColWithRepeater = ({data}) => {
  const {
    primary: {
      title,
      subtitle,
    },
    items,
  } = data;

  const titleHtml = title?.html.replace('{b}', '<strong class="heavy">')
                               .replace('{/b}', '</strong>');
  const subtitleHtml = subtitle?.html;

  return (
    <div className={styles.sectionWrap}>
      <img src={dotOrgBang} alt="LaunchDarkly.org yellow star" className={styles.dotOrgBang}/>
      <Section jumpId={'ourcauses'} sliceName={'TwoColWithRepeater'} className={styles.section}>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.title} dangerouslySetInnerHTML={{__html: titleHtml}} />
            <div className={styles.subtitle} dangerouslySetInnerHTML={{__html: subtitleHtml}} />
          </div>
          <div className={styles.right}>
            {items.map((item) => {
              const {
                icon,
                repeater_title: repeaterTitle,
                content,
                first_link_cta: firstCta,
                first_link_url: firstUrl,
                second_link_cta: secondCta,
                second_link_url: secondUrl,
                third_link_cta: thirdCta,
                third_link_url: thirdUrl,
              } = item;

              const iconUrl = icon?.url;
              const iconAlt = icon?.alt;
              const repeaterTitleText = repeaterTitle?.text;
              const contentHtml = content?.html;
              const firstCtaText = firstCta?.text;
              const firstCtaUrl = firstUrl?.url;
              const secondCtaText = secondCta?.text;
              const secondCtaUrl = secondUrl?.url;
              const thirdCtaText = thirdCta?.text;
              const thirdCtaUrl = thirdUrl?.url;

              return (
                <div className={styles.repeater} key={repeaterTitleText}>
                  <div className={styles.leftCol}>
                    <div className={styles.blueCircle}>
                      <img src={iconUrl} alt={iconAlt} />
                    </div>
                  </div>
                  <div className={styles.rightCol}>
                    <h2>{repeaterTitleText}</h2>
                    <div className={styles.content} dangerouslySetInnerHTML={{__html: contentHtml}} />
                    {firstCtaUrl && (
                      <div className={styles.links}>
                        {firstCtaUrl && <Link to={firstCtaUrl}>{firstCtaText}</Link>}
                        {secondCtaUrl && <Link to={secondCtaUrl}>{secondCtaText}</Link>}
                        {thirdCtaUrl && <Link to={thirdCtaUrl}>{thirdCtaText}</Link>}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Section>
    </div>
  )
}

export default TwoColWithRepeater;