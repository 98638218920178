// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--iaCp+";
export var animateScrollOut = "styles-module--animateScrollOut--Hwy7z";
export var colorSequence = "styles-module--colorSequence--kRq0p";
export var container = "styles-module--container--do2A2";
export var content = "styles-module--content--JmZyl";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--OooUC";
export var grow = "styles-module--grow--ID5sL";
export var growAndShrink = "styles-module--growAndShrink--xeqO8";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--fQr0L";
export var left = "styles-module--left--PERvv";
export var moveBg = "styles-module--move-bg--zZfEE";
export var rotateLoop = "styles-module--rotateLoop--wJM1p";
export var section = "styles-module--section--b5A3U";
export var spin = "styles-module--spin--lW08L";
export var spinCounter = "styles-module--spinCounter--a01U6";
export var stickerFloat = "styles-module--stickerFloat--pFJnk";
export var title = "styles-module--title--k7JkC";