import React from 'react';
import { Section } from 'components';
import * as styles from './styles.module.scss';

const TwoColContent = ({ data }) => {
  const {
    primary: {
      left_title: leftTitle,
      left_content: leftContent,
      right_title: rightTitle,
      right_content: rightContent,
    }
  } = data;

  const leftTitleText = leftTitle?.text;
  const leftContentHtml = leftContent?.html;
  const rightTitleText = rightTitle?.text;
  const rightContentHtml = rightContent?.html;

  return (
    <Section sliceName={'TwoColContent'} className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h3>{leftTitleText}</h3>
          <div dangerouslySetInnerHTML={{__html: leftContentHtml}} />
        </div>
        <div className={styles.content}>
          <h3>{rightTitleText}</h3>
          <div dangerouslySetInnerHTML={{__html: rightContentHtml}} />
        </div>
      </div>
    </Section>
  )
}

export default TwoColContent;
