import React, { useEffect } from 'react';
import { Section, Image } from 'components';
import * as styles from './styles.module.scss';

const ContentBlock = ({ data }) => {
  const {
    primary: {
      image_on_left: img,
      content,
    }
  } = data;

  const contentHtml = content?.html;

  const  debounce = (func) => {
    let timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func,400,event);
    };
  }

  const updatePosition = () => {
    const section = document.querySelector('.section--content-block');
    const block = section.querySelector('.section--content-block > div');
    const blockHeight = block.clientHeight;
    section.style.top = `${-(blockHeight / 2)}px`;
  }

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize',
      debounce((e) => {
        updatePosition();
      })
    );
  });

  return (
    <div className={styles.sectionWrap}>
      <Section sliceName="ContentBlock" className={`${styles.section} section--content-block`} containerClassName={styles.container}>
        <div className={styles.contentInner}>
          <Image className={styles.img} image={img} />
          <div className={styles.content} dangerouslySetInnerHTML={{__html: contentHtml}} />
        </div>
      </Section>
    </div>
  );
}

export default ContentBlock;
