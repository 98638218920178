// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--oy6Sl";
export var animateScrollOut = "styles-module--animateScrollOut--Jx3JI";
export var colorSequence = "styles-module--colorSequence--m2t+W";
export var container = "styles-module--container--BSx0m";
export var content = "styles-module--content--V2-Lw";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--OOgyj";
export var grow = "styles-module--grow--5qou8";
export var growAndShrink = "styles-module--growAndShrink--zr8iM";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--jS+VN";
export var moveBg = "styles-module--move-bg--t3-Oo";
export var rotateLoop = "styles-module--rotateLoop--F6eL2";
export var section = "styles-module--section--ep75s";
export var spin = "styles-module--spin--dUzIN";
export var spinCounter = "styles-module--spinCounter--m5-x2";
export var stickerFloat = "styles-module--stickerFloat--1EFAW";