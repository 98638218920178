// extracted by mini-css-extract-plugin
export var animateScrollIn = "styles-module--animateScrollIn--gWS5j";
export var animateScrollOut = "styles-module--animateScrollOut--nidLR";
export var colorSequence = "styles-module--colorSequence--9Qi7P";
export var container = "styles-module--container--J4Zu1";
export var content = "styles-module--content--7X4Xj";
export var contentInner = "styles-module--contentInner---sU-u";
export var diagonalFadeIn = "styles-module--diagonalFadeIn--6SZO2";
export var grow = "styles-module--grow--Rmg+c";
export var growAndShrink = "styles-module--growAndShrink--kQwsS";
export var growAndShrinkSubtle = "styles-module--growAndShrinkSubtle--f9Xn0";
export var img = "styles-module--img--955AQ";
export var moveBg = "styles-module--move-bg--g945J";
export var rotateLoop = "styles-module--rotateLoop--8ZIGB";
export var section = "styles-module--section--CPfDs";
export var sectionWrap = "styles-module--sectionWrap--vTZy9";
export var spin = "styles-module--spin--FOsMN";
export var spinCounter = "styles-module--spinCounter--jmSHE";
export var stickerFloat = "styles-module--stickerFloat--Eu3Kf";